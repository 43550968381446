import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { baseURL } from '../../../http';
import axios from 'axios';
import './Cards.css'; // Assume this CSS handles general styling for the blog page
import HeaderMain from '../../Header/HeaderMain';
import FooterMain from '../../Footer/FooterMain';

const BlogContent = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);

  // Fetch post by ID from the API when the component mounts
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${baseURL}/blogs_get/${postId}`);
        const fetchedPost = response.data;

        // Check if the post is approved before setting it to state
        if (fetchedPost.IsApproved === 1) {
          setPost(fetchedPost);
        } else {
          console.log('Post not approved');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post) {
    return <div>Post not found</div>;
  }

  const handleBackClick = () => {
    navigate('/blog');
  };

  return (
    <div className="blog-content-page">
      <HeaderMain />

      <div className="back-button-container">
        <ArrowBackIcon className="back-icon" onClick={handleBackClick} />
      </div>

      <div className="post-detail-wrapper">
        <div className="post-detail-content">
          <h1 className="post-detail-title">{post.heading}</h1>

          {/* Use dangerouslySetInnerHTML to render the full content with images */}
          <div
            className="post-full-description"
            dangerouslySetInnerHTML={{ __html: post.Content }} // Renders HTML content with images
          />
        </div>
        <hr />
        <div className="publish-date">
          <p>Published : {post.Created_date}</p>
          <p>Created By : {post.Created_by}</p>
        </div>
      </div>

      <FooterMain />
    </div>
  );
};

export default BlogContent;
