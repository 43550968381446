import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { Save as SaveIcon } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactDOM from "react-dom";
import "./Editor.css";

const CodeViewer = ({
  open,
  handleClose,
  language,
  setLanguage,
  codeString,
  setCodeString,
  mode,
  setMode,
  currentCodeViewer,
  setCurrentCodeViewer,
  selectionRange,
  setOpen,
}) => {

  const [contextMenu, setContextMenu] = useState(null);


  //-------------------------------------------------------------------------
  // CodeViewer ContextMenu
  //-------------------------------------------------------------------------
  const handleContextMenu = (event, codeViewer) => {
    event.preventDefault();
    if (codeViewer.classList.contains("selected")) {
      setCurrentCodeViewer(codeViewer);
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    }
  };

  //-------------------------------------------------------------------------
  // handleContextMenuClose
  //-------------------------------------------------------------------------
  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  //-------------------------------------------------------------------------
  // CodeViewer Edit
  //-------------------------------------------------------------------------
  const handleEdit = () => {
    setMode("edit");
    if (currentCodeViewer) {
      const codeViewerContent = currentCodeViewer.querySelector(
        ".code-viewer-content pre"
      );
      const language =
        currentCodeViewer.querySelector(".language-display").textContent;
      let codeString = codeViewerContent.textContent;
      codeString = codeString
        .split("\n")
        .map((line) => {
          return line.replace(/^\s*\d+\s*/, "");
        })
        .join("\n");
      setCodeString(codeString);
      setLanguage(language);
      setOpen(true);
    }
    handleContextMenuClose();
  };

  //-------------------------------------------------------------------------
  // CodeViewer Delete
  //-------------------------------------------------------------------------
  const handleDelete = () => {
    if (currentCodeViewer) {
      currentCodeViewer.remove();
    }
    handleContextMenuClose();
  };

  //-------------------------------------------------------------------------
  //Code Viewer Languages
  //-------------------------------------------------------------------------

  const languages = [
    { title: "Ada", value: "ada" },
    { title: "C", value: "c" },
    { title: "C#", value: "csharp" },
    { title: "C++", value: "cpp" },
    { title: "COBOL", value: "cobol" },
    { title: "CSS", value: "css" },
    { title: "Dart", value: "dart" },
    { title: "Elixir", value: "elixir" },
    { title: "Erlang", value: "erlang" },
    { title: "F#", value: "fsharp" },
    { title: "Fortran", value: "fortran" },
    { title: "Go", value: "go" },
    { title: "Groovy", value: "groovy" },
    { title: "Haskell", value: "haskell" },
    { title: "HTML", value: "html" },
    { title: "Java", value: "java" },
    { title: "JavaScript", value: "javascript" },
    { title: "Julia", value: "julia" },
    { title: "Kotlin", value: "kotlin" },
    { title: "Lisp", value: "lisp" },
    { title: "Lua", value: "lua" },
    { title: "MATLAB", value: "matlab" },
    { title: "MongoDB", value: "mongodb" },
    { title: "Node.js", value: "nodejs" },
    { title: "Objective-C", value: "objective-c" },
    { title: "OCaml", value: "ocaml" },
    { title: "Pascal", value: "pascal" },
    { title: "Perl", value: "perl" },
    { title: "PHP", value: "php" },
    { title: "Prolog", value: "prolog" },
    { title: "Python", value: "python" },
    { title: "R", value: "r" },
    { title: "React", value: "react" },
    { title: "Ruby", value: "ruby" },
    { title: "Rust", value: "rust" },
    { title: "SAS", value: "sas" },
    { title: "Scala", value: "scala" },
    { title: "Scheme", value: "scheme" },
    { title: "Scratch", value: "scratch" },
    { title: "Shell", value: "shell" },
    { title: "SQL", value: "sql" },
    { title: "Swift", value: "swift" },
    { title: "TypeScript", value: "typescript" },
    { title: "VBA", value: "vba" },
  ];

  //-------------------------------------------------------------------------
  //Insert code viewer
  //-------------------------------------------------------------------------
  const insertCodeViewer = (codeString, language) => {
    if (!selectionRange) return;
    const contentEditor = document.getElementById("edt");
    if (!contentEditor) return;
    const range = selectionRange.cloneRange();
    const commonAncestor = range.commonAncestorContainer;

    if (!contentEditor.contains(commonAncestor)) {
      console.warn("Selection is not within the content editor.");
      return;
    }

    const codeViewerContainer = document.createElement("div");
    codeViewerContainer.classList.add("code-viewer-container");

    codeViewerContainer.addEventListener("contextmenu", (event) =>
      handleContextMenu(event, codeViewerContainer)
    );

    codeViewerContainer.addEventListener("click", () => {
      document
        .querySelectorAll(".code-viewer-container.selected")
        .forEach((el) => {
          el.classList.remove("selected");
        });
      codeViewerContainer.classList.toggle("selected");
    });

    document.body.addEventListener("click", (event) => {
      const isCodeViewerContainer = event.target.closest(
        ".code-viewer-container"
      );
      if (!isCodeViewerContainer) {
        document
          .querySelectorAll(".code-viewer-container.selected")
          .forEach((el) => {
            el.classList.remove("selected");
          });
      }
    });

    populateCodeViewerContainer(codeViewerContainer, codeString, language);
    const endNode = document.createTextNode("\u200B");

    range.deleteContents();
    range.insertNode(endNode);
    range.insertNode(codeViewerContainer);

    range.setStartAfter(endNode);
    range.collapse(true);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.addEventListener("keydown", (e) => {
      if (e.key === "Delete" || e.key === "Backspace") {
        const selectedContainer = document.querySelector(
          ".code-viewer-container.selected"
        );
        if (selectedContainer) {
          selectedContainer.remove();
        }
      }
    });
  };

  //-------------------------------------------------------------------------
  //Update edited content to the code viewer
  //-------------------------------------------------------------------------

  const updateCodeViewer = (codeViewerContainer, codeString, language) => {
    const languageDisplay =
      codeViewerContainer.querySelector(".language-display");

    if (languageDisplay) {
      languageDisplay.textContent = language;

      // Remove existing buttons if any
      const existingEditButton = languageDisplay.querySelector(".edit-button");
      const existingCopyButton = languageDisplay.querySelector(".copy-button");

      if (existingEditButton) existingEditButton.remove();
      if (existingCopyButton) existingCopyButton.remove();

      // Re-add the buttons
      addButtonsToLanguageDisplay(
        languageDisplay,
        codeString,
        codeViewerContainer
      );
    }

  //-------------------------------------------------------------------------
  //Code viewer content
  //-------------------------------------------------------------------------

    const codeViewerContent = codeViewerContainer.querySelector(
      ".code-viewer-content"
    );
    if (codeViewerContent) {
      const newCodeViewerContent = createCodeViewerContent(
        codeString,
        language
      );
      codeViewerContainer.replaceChild(newCodeViewerContent, codeViewerContent);
    }
    codeViewerContainer.contentEditable = false;
  };

  //-------------------------------------------------------------------------
  //Populated Code Viewer
  //-------------------------------------------------------------------------
  
  const populateCodeViewerContainer = (
    codeViewerContainer,
    codeString,
    language
  ) => {
    const languageDisplay = document.createElement("div");
    languageDisplay.classList.add("language-display");
    languageDisplay.textContent = `${language}`;
    codeViewerContainer.appendChild(languageDisplay);

    addButtonsToLanguageDisplay(
      languageDisplay,
      codeString,
      codeViewerContainer
    );

    languageDisplay.contentEditable = false;

    const codeViewerContent = document.createElement("div");
    codeViewerContent.classList.add("code-viewer-content");

    ReactDOM.render(
      <SyntaxHighlighter
        language={language}
        style={docco}
        showLineNumbers={true}
      >
        {codeString}
      </SyntaxHighlighter>,
      codeViewerContent
    );

    codeViewerContainer.appendChild(codeViewerContent);
    codeViewerContent.contentEditable = false;
  };

  const createCodeViewerContent = (codeString, language) => {
    const codeViewerContent = document.createElement("div");
    codeViewerContent.classList.add("code-viewer-content");

    ReactDOM.render(
      <SyntaxHighlighter language={language} style={docco} showLineNumbers>
        {codeString}
      </SyntaxHighlighter>,
      codeViewerContent
    );

    return codeViewerContent;
  };

  //-------------------------------------------------------------------------
  // CodeViewer Edit Button
  //-------------------------------------------------------------------------
  const addButtonsToLanguageDisplay = (
    languageDisplay,
    codeString,
    codeViewerContainer
  ) => {
    const editLanguageButton = document.createElement("button");
    editLanguageButton.innerHTML = '<i class="fas fa-pencil-alt"></i>'; // Adding Font Awesome edit icon
    editLanguageButton.classList.add("edit-button");
    editLanguageButton.addEventListener("click", (event) => {
      event.stopPropagation();

      setMode("edit");

      setCurrentCodeViewer(codeViewerContainer);
      const codeViewerContent = codeViewerContainer.querySelector(
        ".code-viewer-content pre"
      );
      const language =
        codeViewerContainer.querySelector(".language-display").textContent;

      let codeString = codeViewerContent.textContent;
      codeString = codeString
        .split("\n")
        .map((line) => {
          return line.replace(/^\s*\d+\s*/, "");
        })
        .join("\n");

      setCodeString(codeString);
      setLanguage(language);
      setOpen(true);
    });
    editLanguageButton.style.marginLeft = "auto";
    languageDisplay.appendChild(editLanguageButton);

  //-------------------------------------------------------------------------
  // CodeViewer Copy button
  //-------------------------------------------------------------------------
    const copyLanguageButton = document.createElement("button");
    copyLanguageButton.innerHTML = '<i class="far fa-copy"></i>'; // Adding Font Awesome copy icon
    copyLanguageButton.classList.add("copy-button");
    copyLanguageButton.addEventListener("click", (event) => {
      event.stopPropagation();
      navigator.clipboard.writeText(codeString);
      copyLanguageButton.innerHTML = '<i class="fas fa-check"></i>';
      setTimeout(() => {
        copyLanguageButton.innerHTML = '<i class="far fa-copy"></i>';
      }, 5000);
    });
    languageDisplay.appendChild(copyLanguageButton);
  };

  //-------------------------------------------------------------------------
  // CodeViewer Modal box save button
  //-------------------------------------------------------------------------
  const handleOk = () => {
    if (mode === "add") {
      insertCodeViewer(codeString, language);
    } else if (mode === "edit" && currentCodeViewer) {
      updateCodeViewer(currentCodeViewer, codeString, language);
    }
    handleClose();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} className="code-viewer-modal">
        <div className="code-viewer-dialog">
          <IconButton
            id="button-zoom"
            className="enquiry-form-addeducationalMapmodalcancel"
            onClick={handleClose}
          >
            <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
          </IconButton>

          {/* ________________ Heading ____________________ */}
          <div className="code-viewer-dialog-heading">
            <div className="code-viewer-dialog-heading">
              <h5 style={{ fontWeight: "bold" }}>
                {mode === "edit" ? "Edit" : "Add"} Code Here
              </h5>
            </div>
          </div>
          <div className="LangugaeSelection">
            <Autocomplete
              options={languages}
              getOptionLabel={(option) => option.title}
              value={languages.find((lang) => lang.value === language) || null}
              onChange={(event, newValue) =>
                setLanguage(newValue ? newValue.value : "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Language"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="codeEditor">
            <TextField
              label="Code"
              multiline
              rows={4}
              value={codeString}
              onChange={(e) => setCodeString(e.target.value)}
              fullWidth
            />
          </div>
          <div className="editor_saveButton">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleOk}
            >
              <SaveIcon />
              {mode === "edit" ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </Modal>
      <Menu
        keepMounted
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        className="context-menu"
      >
        <MenuItem className="contextMenuItem" onClick={handleEdit}>
          <ListItemIcon className="EditIcon">
            <FaEdit />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem className="contextMenuItem" onClick={handleDelete}>
          <ListItemIcon className="DeleteIcon">
            <FaTrashAlt />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CodeViewer;
