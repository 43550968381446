import React, { useState } from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Tooltip from '@mui/material/Tooltip';
import "./Editor.css";

const ButtonDropdown = ({ windowWidth, handleUnorderedListClick }) => {
  // Separate useState hooks for each dropdown
  const [justifyAnchorEl, setJustifyAnchorEl] = useState(null);
  const [indentAnchorEl, setIndentAnchorEl] = useState(null);
  const [listAnchorEl, setListAnchorEl] = useState(null);

  // State to track selected icons for each dropdown
  const [selectedJustifyIcon, setSelectedJustifyIcon] = useState(
    <FormatAlignJustifyIcon />
  );
  const [selectedIndentIcon, setSelectedIndentIcon] = useState(
    <FormatIndentIncreaseIcon />
  );
  const [selectedListIcon, setSelectedListIcon] = useState(
    <FormatListBulletedIcon />
  );

  const handleClick = (setAnchorEl) => (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (setAnchorEl) => () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (command, icon, setAnchorEl, setSelectedIcon) => {
    document.execCommand(command);
    setSelectedIcon(icon);
    setAnchorEl(null);
  };

  const getIconSize = () => {
    if (windowWidth < 400) return 10;
    if (windowWidth < 600) return 12;
    if (windowWidth < 800) return 15;
    return 20;
  };

  return (
    <div className="toolbar-container">
      <div>
        <Tooltip title="Justify" arrow>
          <button
            onClick={handleClick(setJustifyAnchorEl)}
            className="ctrlActive"
          >
            {selectedJustifyIcon}
            {/* <ArrowDropDownIcon /> */}
          </button>
        </Tooltip>
        <Menu
          anchorEl={justifyAnchorEl}
          open={Boolean(justifyAnchorEl)}
          onClose={handleClose(setJustifyAnchorEl)}
          keepMounted
        >
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "justifyLeft",
                <FormatAlignLeftIcon />,
                setJustifyAnchorEl,
                setSelectedJustifyIcon
              )
            }
          >
            <FormatAlignLeftIcon /> Justify Left
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "justifyCenter",
                <FormatAlignCenterIcon />,
                setJustifyAnchorEl,
                setSelectedJustifyIcon
              )
            }
          >
            <FormatAlignCenterIcon /> Justify Center
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "justifyRight",
                <FormatAlignRightIcon />,
                setJustifyAnchorEl,
                setSelectedJustifyIcon
              )
            }
          >
            <FormatAlignRightIcon /> Justify Right
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "justifyFull",
                <FormatAlignJustifyIcon />,
                setJustifyAnchorEl,
                setSelectedJustifyIcon
              )
            }
          >
            <FormatAlignJustifyIcon /> Justify Full
          </MenuItem>
        </Menu>
      </div>

      <div>
        <Tooltip title="Indent/Outdent" arrow>
          <button
            onClick={handleClick(setIndentAnchorEl)}
            className="ctrlActive"
          >
            {selectedIndentIcon}
            {/* <ArrowDropDownIcon /> */}
          </button>
        </Tooltip>
        <Menu
          anchorEl={indentAnchorEl}
          open={Boolean(indentAnchorEl)}
          onClose={handleClose(setIndentAnchorEl)}
          keepMounted
        >
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "indent",
                <FormatIndentIncreaseIcon />,
                setIndentAnchorEl,
                setSelectedIndentIcon
              )
            }
          >
            <FormatIndentIncreaseIcon /> Indent
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "outdent",
                <FormatIndentDecreaseIcon />,
                setIndentAnchorEl,
                setSelectedIndentIcon
              )
            }
          >
            <FormatIndentDecreaseIcon /> Outdent
          </MenuItem>
        </Menu>
      </div>

      <div>
        <Tooltip title="Lists" arrow>
          <button onClick={handleClick(setListAnchorEl)} className="ctrlActive">
            {selectedListIcon}
            {/* <ArrowDropDownIcon /> */}
          </button>
        </Tooltip>
        <Menu
          anchorEl={listAnchorEl}
          open={Boolean(listAnchorEl)}
          onClose={handleClose(setListAnchorEl)}
          keepMounted
        >
          <MenuItem
            onClick={() => {
              handleUnorderedListClick();
              setSelectedListIcon(<FormatListBulletedIcon />);
              setListAnchorEl(null);
            }}
          >
            <FormatListBulletedIcon /> Unordered List
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuItemClick(
                "insertOrderedList",
                <FormatListNumberedIcon />,
                setListAnchorEl,
                setSelectedListIcon
              )
            }
          >
            <FormatListNumberedIcon /> Ordered List
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ButtonDropdown;
